import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';

import { de } from 'date-fns/locale';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(),
    provideAnimations(),
    provideStore({
      router: routerReducer,
    }),
    provideStoreDevtools({
      connectInZone: true,
    }),
    provideRouterStore({}),
    { provide: MAT_DATE_LOCALE, useValue: de },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    provideDateFnsAdapter({
      parse: {
        dateInput: ['dd.MM.yyyy', 'dd.MM.yy', 'ddMMyy', 'ddMMyyyy'],
      },
      display: {
        dateInput: 'dd.MM.yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'dd.MM.yyyy',
        monthYearA11yLabel: 'MMMM yyyy',
      },
    }),
  ],
};
