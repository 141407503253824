import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('@aki/portal/feature-portal-layout').then(
        (m) => m.webFeaturePortalLayoutRoutes
      ),
  },
];
